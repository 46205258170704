<template lang="pug">
  v-card
    v-scrollable(
      :bottom-offset="20")
      v-card-content
        company-form(
          @on-save="onSave")

</template>

<script>
import { mapActions } from 'vuex'
import CompanyForm from '@/views/settings/globalSettings/company/form'

export default {
  name: 'CompanySettings',

  components: {
    CompanyForm
  },

  methods: {
    ...mapActions('app', ['setPopupMessage']),

    onSave () {
      this.setPopupMessage({
        message: this.$t('infotext.changes_successfully_saved')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
